import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration-2.svg";
import Slider from "components/cards/ThreeColSlider.js";

export default () => (
  <AnimationRevealPage>
    <Hero /> {/* Inicio */}
    <MainFeature /> {/* MiContador */}
    <Features /> {/* Soluciones destacadas */}
    <Portfolio /> {/* Servicios */}
    <MainFeature2 /> {/* Proyectos */}
    <Slider/> {/* Marcas */}
    <FAQ
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="Linea de comunicación"
      heading={
        <>
          ¿Quieres levantar una <span tw="text-primary-500">incidencia?</span>
        </>
      }
    /> {/* Linea de comunicación */}
    <ContactUsForm /> {/* Contacto */}
    <Footer />
  </AnimationRevealPage>
);
