import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/micontador.js";
import Hero from "components/hero/FullWidthWithImage.js";
import Pricing from "components/pricing/ThreePlans.js";
import Features from "components/features/ThreeColWithSideImage.js"
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import FAQ from "components/faqs/SingleCol.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Hero />
      <Pricing />
      {/*<Testimonial
        heading="Our Paying Customers"
      />
  <FAQ />*/}
      <Features />
      <Footer/>
    </AnimationRevealPage>
  );
};
